
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as formulairekRUpiY4AAUMeta } from "/builds/9654815546/hydraclic-v2/nuxt/pages/administration/acteurs/[k]/formulaire.vue?macro=true";
import { default as indexMKZmaWG7tcMeta } from "/builds/9654815546/hydraclic-v2/nuxt/pages/administration/acteurs/[k]/index.vue?macro=true";
import { default as _91k_93L2bzx3KT45Meta } from "/builds/9654815546/hydraclic-v2/nuxt/pages/administration/acteurs/[k].vue?macro=true";
import { default as acteurs8XIk8h99pyMeta } from "/builds/9654815546/hydraclic-v2/nuxt/pages/administration/acteurs.vue?macro=true";
import { default as configurationJ34avlOVf2Meta } from "/builds/9654815546/hydraclic-v2/nuxt/pages/administration/configuration.vue?macro=true";
import { default as formulairescfzDHCg82Meta } from "/builds/9654815546/hydraclic-v2/nuxt/pages/administration/territoires/[k]/formulaire.vue?macro=true";
import { default as indexmx3OZ7bF9XMeta } from "/builds/9654815546/hydraclic-v2/nuxt/pages/administration/territoires/[k]/index.vue?macro=true";
import { default as _91k_93iAstN2OzjWMeta } from "/builds/9654815546/hydraclic-v2/nuxt/pages/administration/territoires/[k].vue?macro=true";
import { default as territoiresFpBheNUuSIMeta } from "/builds/9654815546/hydraclic-v2/nuxt/pages/administration/territoires.vue?macro=true";
import { default as utilisateurs_45permissionsv0ayPdVRrMMeta } from "/builds/9654815546/hydraclic-v2/nuxt/pages/administration/utilisateurs-permissions.vue?macro=true";
import { default as formulaireqhhzUpUCtvMeta } from "/builds/9654815546/hydraclic-v2/nuxt/pages/administration/utilisateurs/[k]/formulaire.vue?macro=true";
import { default as indexNU0HoBkpGtMeta } from "/builds/9654815546/hydraclic-v2/nuxt/pages/administration/utilisateurs/[k]/index.vue?macro=true";
import { default as _91k_937sBje7HvThMeta } from "/builds/9654815546/hydraclic-v2/nuxt/pages/administration/utilisateurs/[k].vue?macro=true";
import { default as utilisateurs6rWrAMJAaXMeta } from "/builds/9654815546/hydraclic-v2/nuxt/pages/administration/utilisateurs.vue?macro=true";
import { default as indexK8gFZbJgiiMeta } from "/builds/9654815546/hydraclic-v2/nuxt/pages/connexion/index.vue?macro=true";
import { default as _91token_93sXTUeqlgwmMeta } from "/builds/9654815546/hydraclic-v2/nuxt/pages/connexion/mot-de-passe-oublie/[token].vue?macro=true";
import { default as indexF7gGDW2wN4Meta } from "/builds/9654815546/hydraclic-v2/nuxt/pages/connexion/mot-de-passe-oublie/index.vue?macro=true";
import { default as mot_45de_45passe_45oubliezGP2febjfLMeta } from "/builds/9654815546/hydraclic-v2/nuxt/pages/connexion/mot-de-passe-oublie.vue?macro=true";
import { default as contactlZnNeV1rapMeta } from "/builds/9654815546/hydraclic-v2/nuxt/pages/contact.vue?macro=true";
import { default as donnees_45personnelles9sFFIe1nyHMeta } from "/builds/9654815546/hydraclic-v2/nuxt/pages/donnees-personnelles.vue?macro=true";
import { default as _91k_93fkMWRDhcW5Meta } from "/builds/9654815546/hydraclic-v2/nuxt/pages/download/[k].vue?macro=true";
import { default as erreuryoc3a5tFHNMeta } from "/builds/9654815546/hydraclic-v2/nuxt/pages/erreur.vue?macro=true";
import { default as formulaire3pKXV4s3NTMeta } from "/builds/9654815546/hydraclic-v2/nuxt/pages/gestion/controles-simultanes/[k]/formulaire.vue?macro=true";
import { default as indexX2tdaMGTZKMeta } from "/builds/9654815546/hydraclic-v2/nuxt/pages/gestion/controles-simultanes/[k]/index.vue?macro=true";
import { default as _91k_93UvrqDIlAZnMeta } from "/builds/9654815546/hydraclic-v2/nuxt/pages/gestion/controles-simultanes/[k].vue?macro=true";
import { default as controles_45simultanesB98dFZAkRUMeta } from "/builds/9654815546/hydraclic-v2/nuxt/pages/gestion/controles-simultanes.vue?macro=true";
import { default as formulaireEBPztF5YIAMeta } from "/builds/9654815546/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail/[k]/formulaire.vue?macro=true";
import { default as index1FPrYBy4GNMeta } from "/builds/9654815546/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail/[k]/index.vue?macro=true";
import { default as _91k_93Fi7pjakMfzMeta } from "/builds/9654815546/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail/[k].vue?macro=true";
import { default as envoi_45de_45mailNMzGrIjbyhMeta } from "/builds/9654815546/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail.vue?macro=true";
import { default as historiqueOj0Tl1rIGlMeta } from "/builds/9654815546/hydraclic-v2/nuxt/pages/gestion/historique.vue?macro=true";
import { default as formulairew64OOpameNMeta } from "/builds/9654815546/hydraclic-v2/nuxt/pages/gestion/import/[k]/formulaire.vue?macro=true";
import { default as indexI1WjVZv1DQMeta } from "/builds/9654815546/hydraclic-v2/nuxt/pages/gestion/import/[k]/index.vue?macro=true";
import { default as _91k_937qqGmOibpvMeta } from "/builds/9654815546/hydraclic-v2/nuxt/pages/gestion/import/[k].vue?macro=true";
import { default as importq0ofjUD5h4Meta } from "/builds/9654815546/hydraclic-v2/nuxt/pages/gestion/import.vue?macro=true";
import { default as formulaireR179uvjAZKMeta } from "/builds/9654815546/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees/[k]/formulaire.vue?macro=true";
import { default as indexFBktL7UM04Meta } from "/builds/9654815546/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees/[k]/index.vue?macro=true";
import { default as _91k_93LlaRFiiqAdMeta } from "/builds/9654815546/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees/[k].vue?macro=true";
import { default as indisponibilites_45programmeeslaDoGpQAnLMeta } from "/builds/9654815546/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees.vue?macro=true";
import { default as formulaireEsAvFSSJHJMeta } from "/builds/9654815546/hydraclic-v2/nuxt/pages/gestion/pei/[k]/formulaire.vue?macro=true";
import { default as indexyS5L2ZKZraMeta } from "/builds/9654815546/hydraclic-v2/nuxt/pages/gestion/pei/[k]/index.vue?macro=true";
import { default as _91k_93CE8sTQzgWwMeta } from "/builds/9654815546/hydraclic-v2/nuxt/pages/gestion/pei/[k].vue?macro=true";
import { default as peiENvIor2ytmMeta } from "/builds/9654815546/hydraclic-v2/nuxt/pages/gestion/pei.vue?macro=true";
import { default as formulaireGpJEM6lfwyMeta } from "/builds/9654815546/hydraclic-v2/nuxt/pages/gestion/tournees/[k]/formulaire.vue?macro=true";
import { default as index7kBObmrPiSMeta } from "/builds/9654815546/hydraclic-v2/nuxt/pages/gestion/tournees/[k]/index.vue?macro=true";
import { default as _91k_93Q3GhZst4nNMeta } from "/builds/9654815546/hydraclic-v2/nuxt/pages/gestion/tournees/[k].vue?macro=true";
import { default as tourneesJBpJsB3fc5Meta } from "/builds/9654815546/hydraclic-v2/nuxt/pages/gestion/tournees.vue?macro=true";
import { default as indexRbl65JtiDtMeta } from "/builds/9654815546/hydraclic-v2/nuxt/pages/index.vue?macro=true";
import { default as index3TDVYHUb00Meta } from "/builds/9654815546/hydraclic-v2/nuxt/pages/profil/index.vue?macro=true";
import { default as profil6S4SyABbCwMeta } from "/builds/9654815546/hydraclic-v2/nuxt/pages/profil.vue?macro=true";
import { default as ressourcesQRPOU7h7W4Meta } from "/builds/9654815546/hydraclic-v2/nuxt/pages/ressources.vue?macro=true";
import { default as tableau_45de_45bordrgB4qxxSDYMeta } from "/builds/9654815546/hydraclic-v2/nuxt/pages/tableau-de-bord.vue?macro=true";
export default [
  {
    name: "administration-acteurs",
    path: "/administration/acteurs",
    meta: acteurs8XIk8h99pyMeta || {},
    component: () => import("/builds/9654815546/hydraclic-v2/nuxt/pages/administration/acteurs.vue"),
    children: [
  {
    name: _91k_93L2bzx3KT45Meta?.name,
    path: ":k()",
    component: () => import("/builds/9654815546/hydraclic-v2/nuxt/pages/administration/acteurs/[k].vue"),
    children: [
  {
    name: "administration-acteurs-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/9654815546/hydraclic-v2/nuxt/pages/administration/acteurs/[k]/formulaire.vue")
  },
  {
    name: "administration-acteurs-k",
    path: "",
    component: () => import("/builds/9654815546/hydraclic-v2/nuxt/pages/administration/acteurs/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "administration-configuration",
    path: "/administration/configuration",
    meta: configurationJ34avlOVf2Meta || {},
    component: () => import("/builds/9654815546/hydraclic-v2/nuxt/pages/administration/configuration.vue")
  },
  {
    name: "administration-territoires",
    path: "/administration/territoires",
    meta: territoiresFpBheNUuSIMeta || {},
    component: () => import("/builds/9654815546/hydraclic-v2/nuxt/pages/administration/territoires.vue"),
    children: [
  {
    name: _91k_93iAstN2OzjWMeta?.name,
    path: ":k()",
    component: () => import("/builds/9654815546/hydraclic-v2/nuxt/pages/administration/territoires/[k].vue"),
    children: [
  {
    name: "administration-territoires-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/9654815546/hydraclic-v2/nuxt/pages/administration/territoires/[k]/formulaire.vue")
  },
  {
    name: "administration-territoires-k",
    path: "",
    component: () => import("/builds/9654815546/hydraclic-v2/nuxt/pages/administration/territoires/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "administration-utilisateurs-permissions",
    path: "/administration/utilisateurs-permissions",
    meta: utilisateurs_45permissionsv0ayPdVRrMMeta || {},
    component: () => import("/builds/9654815546/hydraclic-v2/nuxt/pages/administration/utilisateurs-permissions.vue")
  },
  {
    name: "administration-utilisateurs",
    path: "/administration/utilisateurs",
    meta: utilisateurs6rWrAMJAaXMeta || {},
    component: () => import("/builds/9654815546/hydraclic-v2/nuxt/pages/administration/utilisateurs.vue"),
    children: [
  {
    name: _91k_937sBje7HvThMeta?.name,
    path: ":k()",
    component: () => import("/builds/9654815546/hydraclic-v2/nuxt/pages/administration/utilisateurs/[k].vue"),
    children: [
  {
    name: "administration-utilisateurs-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/9654815546/hydraclic-v2/nuxt/pages/administration/utilisateurs/[k]/formulaire.vue")
  },
  {
    name: "administration-utilisateurs-k",
    path: "",
    component: () => import("/builds/9654815546/hydraclic-v2/nuxt/pages/administration/utilisateurs/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "connexion",
    path: "/connexion",
    meta: indexK8gFZbJgiiMeta || {},
    component: () => import("/builds/9654815546/hydraclic-v2/nuxt/pages/connexion/index.vue")
  },
  {
    name: mot_45de_45passe_45oubliezGP2febjfLMeta?.name,
    path: "/connexion/mot-de-passe-oublie",
    meta: mot_45de_45passe_45oubliezGP2febjfLMeta || {},
    component: () => import("/builds/9654815546/hydraclic-v2/nuxt/pages/connexion/mot-de-passe-oublie.vue"),
    children: [
  {
    name: "connexion-mot-de-passe-oublie-token",
    path: ":token()",
    meta: _91token_93sXTUeqlgwmMeta || {},
    component: () => import("/builds/9654815546/hydraclic-v2/nuxt/pages/connexion/mot-de-passe-oublie/[token].vue")
  },
  {
    name: "connexion-mot-de-passe-oublie",
    path: "",
    meta: indexF7gGDW2wN4Meta || {},
    component: () => import("/builds/9654815546/hydraclic-v2/nuxt/pages/connexion/mot-de-passe-oublie/index.vue")
  }
]
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/builds/9654815546/hydraclic-v2/nuxt/pages/contact.vue")
  },
  {
    name: "donnees-personnelles",
    path: "/donnees-personnelles",
    component: () => import("/builds/9654815546/hydraclic-v2/nuxt/pages/donnees-personnelles.vue")
  },
  {
    name: "download-k",
    path: "/download/:k()",
    meta: _91k_93fkMWRDhcW5Meta || {},
    component: () => import("/builds/9654815546/hydraclic-v2/nuxt/pages/download/[k].vue")
  },
  {
    name: "erreur",
    path: "/erreur",
    meta: erreuryoc3a5tFHNMeta || {},
    component: () => import("/builds/9654815546/hydraclic-v2/nuxt/pages/erreur.vue")
  },
  {
    name: "gestion-controles-simultanes",
    path: "/gestion/controles-simultanes",
    meta: controles_45simultanesB98dFZAkRUMeta || {},
    component: () => import("/builds/9654815546/hydraclic-v2/nuxt/pages/gestion/controles-simultanes.vue"),
    children: [
  {
    name: _91k_93UvrqDIlAZnMeta?.name,
    path: ":k()",
    component: () => import("/builds/9654815546/hydraclic-v2/nuxt/pages/gestion/controles-simultanes/[k].vue"),
    children: [
  {
    name: "gestion-controles-simultanes-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/9654815546/hydraclic-v2/nuxt/pages/gestion/controles-simultanes/[k]/formulaire.vue")
  },
  {
    name: "gestion-controles-simultanes-k",
    path: "",
    component: () => import("/builds/9654815546/hydraclic-v2/nuxt/pages/gestion/controles-simultanes/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "gestion-envoi-de-mail",
    path: "/gestion/envoi-de-mail",
    meta: envoi_45de_45mailNMzGrIjbyhMeta || {},
    component: () => import("/builds/9654815546/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail.vue"),
    children: [
  {
    name: _91k_93Fi7pjakMfzMeta?.name,
    path: ":k()",
    component: () => import("/builds/9654815546/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail/[k].vue"),
    children: [
  {
    name: "gestion-envoi-de-mail-k-formulaire",
    path: "formulaire",
    meta: formulaireEBPztF5YIAMeta || {},
    component: () => import("/builds/9654815546/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail/[k]/formulaire.vue")
  },
  {
    name: "gestion-envoi-de-mail-k",
    path: "",
    component: () => import("/builds/9654815546/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "gestion-historique",
    path: "/gestion/historique",
    meta: historiqueOj0Tl1rIGlMeta || {},
    component: () => import("/builds/9654815546/hydraclic-v2/nuxt/pages/gestion/historique.vue")
  },
  {
    name: "gestion-import",
    path: "/gestion/import",
    meta: importq0ofjUD5h4Meta || {},
    component: () => import("/builds/9654815546/hydraclic-v2/nuxt/pages/gestion/import.vue"),
    children: [
  {
    name: _91k_937qqGmOibpvMeta?.name,
    path: ":k()",
    component: () => import("/builds/9654815546/hydraclic-v2/nuxt/pages/gestion/import/[k].vue"),
    children: [
  {
    name: "gestion-import-k-formulaire",
    path: "formulaire",
    meta: formulairew64OOpameNMeta || {},
    component: () => import("/builds/9654815546/hydraclic-v2/nuxt/pages/gestion/import/[k]/formulaire.vue")
  },
  {
    name: "gestion-import-k",
    path: "",
    meta: indexI1WjVZv1DQMeta || {},
    component: () => import("/builds/9654815546/hydraclic-v2/nuxt/pages/gestion/import/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "gestion-indisponibilites-programmees",
    path: "/gestion/indisponibilites-programmees",
    component: () => import("/builds/9654815546/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees.vue"),
    children: [
  {
    name: _91k_93LlaRFiiqAdMeta?.name,
    path: ":k()",
    component: () => import("/builds/9654815546/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees/[k].vue"),
    children: [
  {
    name: "gestion-indisponibilites-programmees-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/9654815546/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees/[k]/formulaire.vue")
  },
  {
    name: "gestion-indisponibilites-programmees-k",
    path: "",
    component: () => import("/builds/9654815546/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "gestion-pei",
    path: "/gestion/pei",
    meta: peiENvIor2ytmMeta || {},
    alias: ["/gestion/carte"],
    component: () => import("/builds/9654815546/hydraclic-v2/nuxt/pages/gestion/pei.vue"),
    children: [
  {
    name: _91k_93CE8sTQzgWwMeta?.name,
    path: ":k()",
    component: () => import("/builds/9654815546/hydraclic-v2/nuxt/pages/gestion/pei/[k].vue"),
    children: [
  {
    name: "gestion-pei-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/9654815546/hydraclic-v2/nuxt/pages/gestion/pei/[k]/formulaire.vue")
  },
  {
    name: "gestion-pei-k",
    path: "",
    component: () => import("/builds/9654815546/hydraclic-v2/nuxt/pages/gestion/pei/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "gestion-tournees",
    path: "/gestion/tournees",
    meta: tourneesJBpJsB3fc5Meta || {},
    component: () => import("/builds/9654815546/hydraclic-v2/nuxt/pages/gestion/tournees.vue"),
    children: [
  {
    name: _91k_93Q3GhZst4nNMeta?.name,
    path: ":k()",
    component: () => import("/builds/9654815546/hydraclic-v2/nuxt/pages/gestion/tournees/[k].vue"),
    children: [
  {
    name: "gestion-tournees-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/9654815546/hydraclic-v2/nuxt/pages/gestion/tournees/[k]/formulaire.vue")
  },
  {
    name: "gestion-tournees-k",
    path: "",
    component: () => import("/builds/9654815546/hydraclic-v2/nuxt/pages/gestion/tournees/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/9654815546/hydraclic-v2/nuxt/pages/index.vue")
  },
  {
    name: profil6S4SyABbCwMeta?.name,
    path: "/profil",
    meta: profil6S4SyABbCwMeta || {},
    component: () => import("/builds/9654815546/hydraclic-v2/nuxt/pages/profil.vue"),
    children: [
  {
    name: "profil",
    path: "",
    component: () => import("/builds/9654815546/hydraclic-v2/nuxt/pages/profil/index.vue")
  }
]
  },
  {
    name: "ressources",
    path: "/ressources",
    component: () => import("/builds/9654815546/hydraclic-v2/nuxt/pages/ressources.vue")
  },
  {
    name: "tableau-de-bord",
    path: "/tableau-de-bord",
    meta: tableau_45de_45bordrgB4qxxSDYMeta || {},
    component: () => import("/builds/9654815546/hydraclic-v2/nuxt/pages/tableau-de-bord.vue")
  }
]