import routerOptions0 from "/builds/9654815546/hydraclic-v2/nuxt/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/builds/9654815546/hydraclic-v2/nuxt/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}